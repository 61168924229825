import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';
import { BORDER_RADIUS, Color } from '../../constants';
import { ContainerRelative, GroupContainer, Spacer } from '../Container';
import { Label } from '../Typography';
import { defaultFontStyle, fontConfig, T12S2 } from '../T';
import styled from 'styled-components';
import kakao_logo from '../../public/kakao.svg';
import apple_logo from '../../public/appleIcon.svg';
import profileCamera from '../../public/profileCamera.svg';

export const styleFeedback = {
  transition: 'transform 100ms, opacity 200ms',
  cursor: 'pointer',
  outline: 'none',
  letterSpacing: '0.5px !important',
};

export const ButtonFeedback = styled.button(({ disabled }) => ({
  ...styleFeedback,
  background: 'none',
  border: 'none',
  pointerEvents: disabled ? 'none' : 'initial',
  margin: 0,
}));

export const Button = styled(ButtonFeedback)({
  ...defaultFontStyle,
  borderRadius: BORDER_RADIUS,
  backgroundColor: '#28323c19',
  textAlign: 'left',
});

export const ShadowdButton = styled(Button)({
  ...defaultFontStyle,
  display: 'flex',
  boxShadow: '0 5px 5px 0 #28323c19',
  backgroundColor: Color.background,
  padding: 16,
});

export const FlatButton = styled(ButtonFeedback)({
  ...defaultFontStyle,
  ...fontConfig(16),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 64,
  flex: 1,
});

export const BasicButton = styled(ButtonFeedback)({
  ...defaultFontStyle,
  ...fontConfig(16),
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  color: '#ffffff',
  fontWeight: 'bold',
  'font-size': '14px;',
  '&:disabled': {
    border: 'solid 1px #c5c8ce',
    'background-color': '#f7f8f9',
    color: '#c5c8ce',
  },
});

export const GroupButtonBase = styled(ButtonFeedback)({
  ...defaultFontStyle,
  ...fontConfig(16),
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  color: '#ffffff',
  'font-size': '14px;',
  '&:disabled': {
    border: 'solid 1px #e9ebee',
    'background-color': '#ffffff',
    color: '#e9ebee',
    padding: '14px 14px 14px 15px',
  },
});

export const DarkButton = styled(BasicButton)({
  backgroundColor: Color.dark,
  width: '100%',
});

export const BlueButton = styled(BasicButton)({
  backgroundColor: Color.blue,
});

export const GreenButton = styled(BasicButton)({
  backgroundColor: Color.green,
});

export const WhiteButton = styled(BasicButton)({
  color: '#374553',
  border: '1px solid #C5C8CE',
});

export const GreenGroupButton = styled(GroupButtonBase)({
  backgroundColor: Color.ticketGreen,
  width: '6.5em',
  fontSize: '14px',
});

export const IconButton = styled(BasicButton)<{ bgcolor?: string; icon?: string; width?: string }>`
  background: ${({ icon = '' }) => `url(/${icon}.svg) no-repeat 50% 50%`};
  background-color: ${({ bgcolor = 'none' }) => bgcolor};
  width: ${({ width = '50px' }) => width};
`;

export const KakaoIconButton = styled(BasicButton)({
  width: '100%',
  alignItems: 'center',
  backgroundColor: Color.kakao,
  color: Color.kakaoBrown,
});

export const Icon = styled(Image)<{ bgcolor?: string }>`
  background-color: ${({ bgcolor = 'none' }) => bgcolor};
`;

export const ProfileImageButton = ({
  onClick,
  src,
  isMobile,
}: {
  isMobile: boolean;
  src: string;
  onClick: () => void;
}) => (
  <ContainerRelative>
    <input
      id="photo_file"
      type={isMobile ? '' : 'file'}
      accept="image/*"
      style={{ display: 'none' }}
      onClick={onClick}
    />
    <label htmlFor="photo_file" style={{ cursor: 'pointer' }}>
      <Image src={src} className="profile_image" width="120" height="120" />
      <div style={{ zIndex: 5, position: 'absolute', bottom: '6px', right: 0 }}>
        <Image unoptimized src={profileCamera} width={32} height={32} alt="camera" />
      </div>
    </label>
  </ContainerRelative>
);

export const KakaoLoginButton = ({ children, onClick }: any) => {
  return (
    <KakaoIconButton onClick={onClick}>
      <Image src={kakao_logo} alt="kakao_logo" width={20} height={20} />
      <Spacer />
      <Label
        style={{
          backgroundColor: 'transparent',
          color: Color.kakaoBrown,
          fontSize: '14px',
        }}
      >
        {children}
      </Label>
    </KakaoIconButton>
  );
};

export const AppleLoginButton = ({ children, onClick }: any) => (
  <BasicButton style={{ width: '100%', backgroundColor: Color.primary1 }} onClick={onClick}>
    <Image src={apple_logo} alt="apple_logo" width={20} height={20} />
    <Spacer />
    <Label style={{ color: 'white', fontSize: '14px' }}>{children}</Label>
  </BasicButton>
);

export const LightButton = styled(DarkButton)<{
  border?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  background-color: ${Color.background};
  border: ${({ border = '' }) => border};
  color: ${Color.primary1};
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '100%' }) => height};
  border-radius: ${({ borderRadius = '' }) => borderRadius};
`;

export const RoundButton = styled(BasicButton)({
  width: '32px',
  height: '32px !important',
  color: '#343434',
  background: '#c5c8ce',
  borderRadius: '50px',
});

export const StyledLink = styled(NextLink)({
  ...defaultFontStyle,
  'letter-spacing': '-0.18px',
  color: 'red !important',
  backgroundColor: 'red !important',
  '&:hover': {
    color: Color.fontColor,
  },
});

export const Link = ({ children, href }: { children: ReactNode; href: string }) => (
  <StyledLink href={href}>
    <Label>{children}</Label>
  </StyledLink>
);

export const ChevronLink = ({
  children,
  link,
  subText,
  size,
  padding,
}: {
  children: any;
  link: string;
  subText?: string;
  size?: string;
  padding?: string;
}) => (
  <Link href={link}>
    <GroupContainer>
      <Label
        style={{
          position: 'relative',
          width: '100%',
          background: `url(/arrow-right.svg) no-repeat right`,
          fontSize: size ? size : '16px',
          padding: padding ? padding : '10px 0',
          color: Color.primary2,
          cursor: 'pointer',
        }}
      >
        <T12S2
          style={{ position: 'absolute', right: 28, top: 0, height: '100%', display: 'flex', alignItems: 'center' }}
        >
          {subText}
        </T12S2>
        {children}
      </Label>
    </GroupContainer>
  </Link>
);

export const Chevron = ({
  children,
  subText,
  size,
  padding,
  onClick,
}: {
  children: any;
  subText?: string;
  size?: string;
  padding?: string;
  onClick: any;
}) => (
  <GroupContainer onClick={onClick}>
    <Label
      style={{
        position: 'relative',
        width: '100%',
        background: `url(/arrow-right.svg) no-repeat right`,
        fontSize: size ? size : '16px',
        padding: padding ? padding : '10px 0',
        color: Color.primary2,
        cursor: 'pointer',
      }}
    >
      <T12S2 style={{ position: 'absolute', right: 28, top: 0, height: '100%', display: 'flex', alignItems: 'center' }}>
        {subText}
      </T12S2>
      {children}
    </Label>
  </GroupContainer>
);

export const ChevronLinkImage = ({
  children,
  link,
  src,
  size,
  padding,
}: {
  children: any;
  link: string;
  src: any;
  size?: string;
  padding?: string;
}) => (
  <Link href={link}>
    <GroupContainer>
      <Image src={src} alt="menu_image" width="24" height="24" className="next_image" />
      <Label
        style={{
          width: '100%',
          background: `url(/arrow-right.svg) no-repeat right`,
          marginLeft: 14,
          fontSize: size ? size : '16px',
          padding: padding ? padding : '10px 0',
          color: Color.primary2,
          cursor: 'pointer',
        }}
      >
        {children}
      </Label>
    </GroupContainer>
  </Link>
);
