import { useRouter } from 'next/router';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { BlueButton } from '../Button';

interface Props {
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const APP_STORE_URL =
  'https://apps.apple.com/kr/app/%EC%8A%A4%ED%8F%AC%EC%B8%A0%EB%AA%AC%EC%8A%A4%ED%84%B0/id1597689701';

const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=kr.co.wepeak.sportsmonster';

const InstallModal: FC<Props> = ({ isModalOpen, setModalOpen }) => {
  const router = useRouter();
  const { isIOS } = useDeviceDetect();
  const isMobile = Boolean(
    globalThis?.navigator?.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  );

  const handleRoute = () => {
    setModalOpen(false);
    if (isIOS && isMobile) return router.push(APP_STORE_URL);
    if (!isIOS && isMobile) return router.push(PLAY_STORE_URL);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={() => setModalOpen(false)} centered>
        <div className="modal-container">
          <div className="modal-content-title">
            밴드 연동 기능은 스포츠몬스터
            <br /> 앱에서만 이용하실 수 있습니다.
            <br />
            {isMobile && '지금 앱을 설치하시겠습니까?'}
          </div>
          <div className="modal-menu">
            <BlueButton onClick={handleRoute}>{isMobile ? '스포츠몬스터 앱 설치하기' : '닫기'}</BlueButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InstallModal;
