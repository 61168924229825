import { Cart, Item, OnlineItem } from './types';
import dayjs from 'dayjs';
import { atom, DefaultValue, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { DayValue } from 'react-modern-calendar-datepicker';
import { Product, Ticket } from 'swingby-shared';

dayjs.locale('ko');

const { persistAtom } = recoilPersist();

const initialToken = typeof window === 'undefined' ? null : localStorage.getItem('token') || null;

export const tokenState = atom<string | null>({
  key: 'session-token',
  default: initialToken,
});

export const tokenSelector = selector<string | null>({
  key: 'session-token-selector',
  get: ({ get }) => get(tokenState),
  set: ({ set }, token) => {
    if (typeof token == 'string') {
      set(tokenState, token);
      localStorage.setItem('token', token);
    }
  },
});

export let signupEmailState = atom({
  key: 'signup-email',
  default: '',
});

export let signupPasswordState = atom({
  key: 'signup-password',
  default: '',
});

export let signupCellPhoneState = atom({
  key: 'signup-cellPhone',
  default: '',
});

export let signupVerifiedCellPhoneState = atom({
  key: 'signup-verified-cellPhone',
  default: '',
});

export let signuptermAllState = atom({
  key: 'signup-term-all',
  default: false,
});

export let signuptermAdultState = atom({
  key: 'signup-term-adult',
  default: false,
});

export let signuptermUsageState = atom({
  key: 'signup-term-usage',
  default: false,
});

export let signuptermPrivacyState = atom({
  key: 'signup-term-privacy',
  default: false,
});

export let signuptermMarketingState = atom({
  key: 'signup-term-marketing',
  default: false,
});

export let signupAppleIdState = atom({
  key: 'signup-apple-id',
  default: '',
  effects: [persistAtom],
});

export let findEmailSuccessState = atom<string | null>({
  key: 'find-email-success',
  default: null,
});

export let findPasswordCellPhoneState = atom({
  key: 'find-password-cellphone',
  default: '',
});

export let findPasswordVerifiedCellPhoneState = atom({
  key: 'find-password-verified-cellphone',
  default: '',
});

export let bandPrevPageState = atom({
  key: 'band-prev-page',
  default: '',
});

export const ticketDateState = atom<DayValue>({
  key: 'ticket-date',
  default: null,
  effects: [persistAtom],
});

export const onlineItemsState = atom<OnlineItem[]>({
  key: 'online-items',
  default: [],
});

export const onlineItemsTransformState = selector<any>({
  key: 'online-items-transform',
  get: ({ get }) => get(onlineItemsState),
  set: ({ set }, newItems) => {
    let items: OnlineItem[] = [];
    newItems.map((item: Item) => {
      const { metaData, name, uid, price, __typename, salesTime, salesStartDate, salesEndDate } = item;

      let discountValue = 5;

      if ('packageItems' in item) {
        const ticketQuantity = item.packageItems?.reduce((acc, cur) => {
          return acc + cur.quantity;
        }, 0);

        if (ticketQuantity === 2 && item.metaData?.tag === 'low') discountValue = 8;
        if (ticketQuantity === 2 && item.metaData?.tag === 'high') discountValue = 7;
        if (ticketQuantity === 4 && item.metaData?.tag === 'low') discountValue = 10;
        if (ticketQuantity === 4 && item.metaData?.tag === 'high') discountValue = 10;
      }

      const itemPrice = metaData && 'packageOriginalPrice' in metaData ? metaData.packageOriginalPrice || 0 : price;

      const originalPrice = itemPrice && Math.round((itemPrice * (100 / (100 - discountValue))) / 1000) * 1000;

      const tempItem: OnlineItem = {
        typename: __typename,
        uid,
        name,
        metaData,
        price: itemPrice,
        discountValue,
        quantity: 0,
        originalPrice,
        salesStartDate,
        salesEndDate,
        salesTime,
        usingHours: (item as Ticket).usingHours || (item as Product).metaData?.packageUsingHours,
      };

      if ('packageItems' in item) {
        tempItem.packageItems = item.packageItems;
      }

      items.push(tempItem);
    });
    set(onlineItemsState, newItems instanceof DefaultValue ? newItems : [...items]);
  },
});

export const onlineItemsSelector = selector<any>({
  key: 'online-items-selector',
  get: ({ get }) => {
    const onlineItems = get(onlineItemsState);

    const time = get(selectedTimeState);

    const exposureItems = onlineItems?.filter((item: OnlineItem) => {
      const timeAvailable = time ? item.salesTime && item.salesTime[0] <= time && item.salesTime[1] >= time : true;
      return timeAvailable;
    });

    const totalPrice = onlineItems?.reduce((acc, cur) => {
      return acc + cur.price * cur.quantity;
    }, 0);

    const totalItemCount = onlineItems?.reduce((acc, cur) => {
      return acc + cur.quantity;
    }, 0);

    const totalTicketCount = onlineItems.reduce((acc: number, cur: OnlineItem) => {
      let ticketQuantity = 0;

      if (cur.typename === 'Product' && cur.packageItems) {
        cur.packageItems?.map((packageItem) => {
          if (packageItem.ticket) ticketQuantity += packageItem.quantity;
        });
      } else if (cur.typename === 'Ticket') {
        ticketQuantity = 1; // 단일 티켓일 경우
      }

      return acc + cur.quantity * ticketQuantity;
    }, 0);

    return { totalPrice, totalTicketCount, totalItemCount, exposureItems };
  },
});

export const selectedTimeState = atom<string | undefined | null>({
  key: 'selected-time',
  default: null,
  effects: [persistAtom],
});

export const orderState = atom<string | string[] | undefined>({
  default: '',
  key: 'orderUid',
});

export const nameState = atom<string>({
  default: '',
  key: 'name',
});

export const genderState = atom<string>({
  default: '',
  key: 'gender',
});

export const birthState = atom<string>({
  default: '',
  key: 'birth',
});

export const passAgeGroupState = atom<string>({
  default: '',
  key: 'season-pass-age-group',
});

export const passTermState = atom<boolean>({
  default: false,
  key: 'season-pass-terms',
});

export const passProfileFile = atom<null | File>({ default: null, key: 'season-pass-profile-file' });

export const passProfilePreview = atom<string>({ default: '/profileDefault.svg', key: 'season-pass-profile-image' });

export const tapState = atom<string>({
  default: 'alarm',
  key: 'tap-state',
});

export const startGetPaymentStatusState = atom<boolean>({
  default: false,
  key: 'start_get_payment_status',
});

export const paymentErrorState = atom<boolean>({
  default: false,
  key: 'payment_error',
});

export const paymentErrorMsgState = atom<string>({
  default: '',
  key: 'payment_error_msg',
});

export const cancelPaymentSuccessState = atom<boolean>({
  default: false,
  key: 'cancel_payment_success',
});

export const cancelPaymentErrorState = atom<boolean>({
  default: false,
  key: 'cancel_payment_error',
});

export const welcomeCouponModalState = atom<boolean>({
  key: 'welcome-coupon-modal-state',
  default: true,
});

export const MaintenanceModalState = atom<boolean>({
  key: 'maintenance-modal-state',
  default: true,
});

export const cartState = atom<Cart[]>({
  key: 'cart-state',
  default: [],
});
