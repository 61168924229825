import { useRouter } from 'next/router';
import React, { FC, useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Color } from '../../../constants';
import { TextTitle } from '../../Typography';
import Image from 'next/image';
import { useRecoilState } from 'recoil';
import { bandPrevPageState } from '../../../atoms';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { Band as IBand } from 'swingby-shared';
import { useQuery } from '@apollo/client';
import { bandVar, userVar } from '../../../apollo/cache';
import { QUERY_BAND_INFO } from '../../../apollo/queries';
import { IResult } from '../../../apollo/types';
import InstallModal from '../../Modal/InstallModal';
import IconTime from '../../../public/icon-time.svg';

interface Props {
  title: string;
  src?: string;
  appearance?: string;
  type?: string;
  tab?: boolean;
  data?: any;
}

const BackNav: FC<Props> = ({ title, src, appearance, type, tab }) => {
  const router = useRouter();
  const user = userVar();
  const { isMobile } = useDeviceDetect();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showTimeLeft, setShowTimeLeft] = useState<boolean>(false);
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const [prevPage, setPrevPage] = useRecoilState(bandPrevPageState);
  const storage = globalThis?.sessionStorage;
  const prevPath = storage?.getItem('prevPath');

  const { data: band } = useQuery<IResult<IBand>>(QUERY_BAND_INFO, {
    skip: !user,
    fetchPolicy: 'network-only',
    onCompleted({ result }) {
      if (result) bandVar(result);
    },
  });

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  const pathHandler = () => {
    if (prevPath === 'null') return router.push('/');
    if (router.pathname === '/band') {
      if (prevPage) return router.push(prevPage);
      return router.push('/');
    }
    if (router.pathname === '/ticketbox') return router.push('/ticket');
    if (router.pathname === '/ticketbox/orderDetail/[uid]') return router.push('/ticketbox');
    if (router.pathname === '/ticketbox/ticketDetail/[uid]') {
      if (prevPath) return router.push(prevPath);
      return router.push('/ticketbox');
    }
    if (router.pathname === '/ticket/season-pass/photo-guide') return router.replace('/ticket/season-pass');
    if (router.pathname === '/play') return;
    if (router.pathname === '/play/history') return router.push('/play');
    if (router.pathname === '/play/mission') return router.push('/play');
    router.back();
  };

  const renderBandStatus = () => {
    if (band)
      return (
        <SyncButton
          className="synced"
          onClick={() => {
            if (!showTimeLeft) {
              setShowTimeLeft(true);
              setTimeout(() => setShowTimeLeft(false), 1500);
            }
          }}
        >
          연동중
          <Image src={IconTime} width={14} height={14} alt="time_icon" />
        </SyncButton>
      );
    else
      return (
        <SyncButton
          onClick={() => {
            setPrevPage(router.pathname);
            if (isMobile) router.push('/band');
            else setShowInstallModal(true);
          }}
        >
          밴드등록
        </SyncButton>
      );
  };

  const renderEnteredTime = () => {
    const enterAtAsMinutes = dayjs().diff(band?.result.userTicket?.enterAt, 'minute');
    const hour = Math.floor(enterAtAsMinutes / 60);
    const minutes = enterAtAsMinutes % 60;
    if (band)
      return (
        <Time className={showTimeLeft ? 'show' : ''}>
          입장 후{' '}
          <strong>
            {hour}시간 {minutes}분
          </strong>{' '}
          지났습니다
        </Time>
      );
    else return null;
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  }, []);

  if (type === 'play' || type === 'playSub') {
    return (
      <>
        <Wrapper>
          <Container className="play" appearance="normal">
            <NavBack onClick={pathHandler} className="play">
              <BackIcon src="/icon-play.svg" className={type === 'playSub' ? 'play-sub' : ''} />
              <NavTitle style={{ color: '#fff' }}>{title}</NavTitle>
            </NavBack>
            <Menu>{renderBandStatus()}</Menu>
          </Container>
          {renderEnteredTime()}
        </Wrapper>
        <InstallModal isModalOpen={showInstallModal} setModalOpen={setShowInstallModal} />
      </>
    );
  }

  return (
    <Wrapper>
      <Container
        appearance={appearance}
        tab={tab}
        className={scrollPosition < 24 ? '' : appearance === 'normal' ? '' : 'shadowed'}
      >
        <NavBack onClick={pathHandler}>
          <BackIcon src="/arrow-left.svg" className={scrollPosition >= 24 && appearance === 'normal' ? 'hide' : ''} />
          <NavTitle>{title}</NavTitle>
        </NavBack>
        {src ? (
          <Image src={src} alt="icon" width="22px" height="22px" onClick={() => router.push('/users/setting')} />
        ) : (
          <HomeIcon onClick={() => router.push('/')} />
        )}
      </Container>
    </Wrapper>
  );
};

export default memo(BackNav);

const Wrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 15;
  width: 100vw;
  max-width: 640px;
  &.overlayClose {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
  }
`;

const Container = styled.div<{ appearance?: String; tab?: boolean }>`
  width: 100%;
  height: 56px;
  max-width: 640px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${({ appearance }) => (appearance === 'normal' ? '' : '#ffffff')};
  padding: 19px 20px;
  z-index: 20;
  &.shadowed:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 8px;
    background-image: url('/bg-nav-shadow.png');
    background-repeat: repeat-x;
    background-size: 16px auto;
    opacity: 0.3;
  }
  &.play {
    background: linear-gradient(#53cca2, #72acc6);
    height: 70px;
  }
`;

export const BackIcon = styled.img`
  opacity: 1;
  transition: opacity 0.15s;
  -webkit-transition: opacity 0.15s;
  &.hide {
    opacity: 0;
    transition: opacity 0.15s;
    -webkit-transition: opacity 0.15s;
  }
  &.play-sub {
    animation: 0.3s linear 1 both rotateCCW;
  }
  &.play {
    animation: 0.3s linear 1 reverse both rotateCCW;
  }
`;

const HomeIcon = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/icon-home.svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin: -6px -4px 0 0;
  cursor: pointer;
`;

const NavBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NavTitle = styled(TextTitle)`
  color: ${Color.primary1};
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0 8px;
`;

const Menu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: -6px;
`;

export const Time = styled.div`
  display: none;
  position: fixed;
  top: 48px;
  left: 5%;
  width: 90%;
  padding: 6px;
  background-color: #000000cc;
  border-radius: 10px;
  color: #ffffff66;
  text-align: center;
  z-index: 20;
  strong {
    color: #fff;
  }
  &.show {
    display: block;
    animation: 0.5s linear 1s 1 both disappear;
  }
`;

const SyncButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 30px;
  padding: 0 10px 0 10px;
  cursor: pointer;
  border: 1px solid #ffffff66;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  &.synced {
    color: #fff;
    img {
      width: 14px;
    }
  }
`;
